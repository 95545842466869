import subcategoriesAPI from "@/API/endpoints/subcategories";
import translationsAPI from "@/API/endpoints/translations";
import Vue from "vue";

const state = {
    subcategories: [],
};

const getters = {
    getSubcategories: (state) => state.subcategories,
};

const mutations = {
    setSubcategories(state, payload) {
        state.subcategories = payload.sort((a, b) => a.id - b.id);
    },
};

const actions = {
    async fetchSubcategories({ commit, rootGetters }) {
        const res = await subcategoriesAPI.getAll();
        const subcategories = Object.values(res.data);
        const translations = rootGetters["translations/getTranslations"];
        const filteredSubcategories = translations
            .map((translation) => {
                const subcategory = subcategories.find(
                    (subcategory) => subcategory.tag === translation.tag
                );
                return subcategory
                    ? {
                          ...translation,
                          category: subcategory.category,
                          id: subcategory.id || 'N/A',
                      }
                    : null;
            })
            .filter((translation) => translation !== null);

        commit("setSubcategories", filteredSubcategories);
    },
    async createSubcategory(context, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Creating Subcategory...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                await subcategoriesAPI.create(payload);
                Vue.swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                    title: "Success!",
                    text: "Subcategory has been created!",
                });
                // dispatch("fetchSubcategories");
            },
        });
    },
    async updateSubcategory({dispatch}, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Editing Subcategory...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                const res = await subcategoriesAPI.getSingle(payload.tag);
                const id = Object.keys(res.data)[0];
                await subcategoriesAPI.update({
                    data: JSON.stringify(payload.id),
                    id: id + "/id",
                });
                Vue.swal.fire({
                    position: "top-right",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                    title: "Success!",
                    text: "Subcategory has been updated!",
                });
                dispatch("fetchSubcategories")
            },
        });
    },
    async deleteSubcategory({ dispatch }, payload) {
        const res = await subcategoriesAPI.getSingle(payload);
        const id = Object.keys(res.data)[0];
        Vue.swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f00",
                cancelButtonColor: "#999",
                confirmButtonText: "Yes, delete it!",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    Vue.swal.fire({
                        icon: "loading",
                        title: "Deleting Subcategory...",
                        width: "300px",
                        showConfirmButton: false,
                        didOpen: async () => {
                            Vue.swal.showLoading();
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "en",
                            });
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "az",
                            });
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "ru",
                            });
                            await subcategoriesAPI.delete(id);
                            Vue.swal.fire({
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1000,
                                title: "Deleted",
                                text: "Subcategory has been deleted",
                            });
                            dispatch("translations/fetchTranslations", null, {
                                root: true,
                            });
                        },
                    });
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
