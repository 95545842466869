<template>
  <div v-if="i18nisLoaded" id="main-wrapper-rapper">
    <div v-if="isMobile">
      <Header @toggleMenu="toggleMenu" />
      <div :class="{ 'blurred': isMenuOpen }">
        <About id="about" />
        <WhatWeDo />
        <AviationProducts id="aviation" />
        <GSE />
        <RawChemicals />
        <Consulting />
        <Contacts id="contacts" />
        <Footer />
      </div>
    </div>
    <div v-else :style="'opacity: ' + (isLoading ? '0' : '1')">
      <Header @navigating="triggerNavigating" id="header" @toggleMenu="toggleMenu" />
      <div id="rapper-wrapper">
        <div v-show="true" ref="main-content" id="main-content" :class="{ 'blurred': isMenuOpen }">
          <About id="about" />
          <WhatWeDo :isActive="isActive" id="wwd" />
          <div id="products-content">
            <AviationProducts :isActive="isActive" :onlyContent="true" id="aviation" />
            <GSE :isActive="isActive" :onlyContent="true" id="gse" />
            <RawChemicals :isActive="isActive" :onlyContent="true" id="raw-chemicals" />
            <Consulting :isActive="isActive" :onlyContent="true" id="consulting" />
          </div>
          <Contacts :isActive="isActive" id="contacts" />
          <Footer id="footer" />
        </div>
        <div v-show="true" id="products-background">
          <div id="blank"></div>
          <AviationProducts :onlyContent="false" id="aviation-bg" />
          <GSE :isActive="isActive" :onlyContent="false" id="gse-bg" />
          <RawChemicals :onlyContent="false" id="raw-chemicals-bg" />
          <Consulting :onlyContent="false" id="consulting-bg" />
        </div>
      </div>
    </div>
    <div class="isLoading" v-if="isLoading">Loading...</div>
  </div>
</template>

<script>
import Header from '../../components/main/header/Header';
import About from '../../components/main/about/About';
import WhatWeDo from '../../components/main/about/WhatWeDo';
// import Hero from '../../components/main/Hero';
import AviationProducts from '../../components/main/products/AviationProducts';
import GSE from '../../components/main/products/GSE';
import RawChemicals from '../../components/main/products/RawChemicals';
import Consulting from '../../components/main/products/Consulting';
import Contacts from '../../components/main/contacts/Contacts';
import Footer from '../../components/main/footer/Footer.vue';
import _ from "lodash";

export default {
  name: 'Home',
  data() {
    return {
      scroll: 0,
      down: true,
      isMenuOpen: false,
      scrollPos: 0,
      sections: [],
      currentSection: {},
      scrolledDown: false,
      allowSmallScroll: false,
      navigating: false,
      isSectionsSet: false,
      debouncedFunction: null,
      isActive: 0,
      isLoading: false,
      allowDebounce: true,
    }
  },
  props: {
    i18nisLoaded: Boolean,
    resized: Boolean,
  },
  components: {
    Header,
    About,
    WhatWeDo,
    AviationProducts,
    GSE,
    RawChemicals,
    Consulting,
    Contacts,
    Footer
  },
  updated() {
    this.cancelDebounce();
    this.debouncedFunction = _.debounce(() => {
      if (!this.isMobile && this.allowDebounce) {
        this.allowDebounce = false;
        setTimeout(() => {
          this.allowDebounce = true;
        }, 1000)
        this.initAnimatingSections();
      }
    }, 300)
    if (!this.isMobile) {
      this.debouncedFunction();
    }
  },
  watch: {
    scrollPos(newVal, oldVal) {
      if (!this.navigating && !this.isMobile) {
        // console.log('oldVal ', oldVal, ' newVal ', newVal);
        let footer = document.getElementById('footer');
        this.currentSection = this.sections.find(c => newVal >= c.top && newVal < c.bottom);
        let content = this.$refs['main-content'];
        let productsBackground = document.getElementById('products-background');
        if (newVal <= 0) {
          content.style.top = 0;
        } if (newVal < oldVal) {
          if (content.style.top.slice(0, -2) < 0) {
            content.style.transition = 'none';
            productsBackground.style.transition = 'none';
            content.style.top = +content.style.top.slice(0, -2) + (oldVal - newVal) + 'px';
            productsBackground.style.top = +content.style.top.slice(0, -2) + (oldVal - newVal) + 'px';
            // console.log('scrolled up by ', (oldVal - newVal));
          } else {
            content.style.top = 0;
          }
        } else {
          content.style.transition = 'top 1s ease-in-out';
          productsBackground.style.transition = 'top 1s ease-in-out';
          let curr = this.currentSection;
          // let previous = this.sections.find(s => s.index === curr.index - 1)
          if (newVal < curr?.bottom) {
            if (oldVal < curr.triggerPoint) {
              //SLOW SCROLL
              // if (newVal < this.largeSection.triggerPoint - 50) {
              //   this.scrolledDown = false;
              // }
              // if (curr?.height > window.innerHeight) {
              //   if (this.allowSmallScroll) {
              //     content.style.transition = 'top 0.5s linear';
              //     content.style.top = +content.style.top.slice(0, -2) - (curr.triggerPoint - curr.top - 50) / 2 + 'px';
              //     window.scrollTo({
              //       top: curr.triggerPoint - 50,
              //       behavior: 'instant'
              //     })
              //     this.preventScroll();
              //     this.scrolledDown = true;
              //     this.allowSmallScroll = false;
              //   }
              //   if (!this.scrolledDown) {
              //     this.preventScroll();
              //     setTimeout(() => {
              //       this.allowSmallScroll = true;
              //     }, 500)
              //   }
              // }
            } else {
              if (curr.index > 2 && curr.index < 6) {
                productsBackground.style.transition = 'none';
              } else {
                productsBackground.style.transition = 'top 1s ease-in-out';
              }
              this.isActive = 0;
              setTimeout(() => {
                this.isActive = curr.index;
              }, 1)
              this.allowDebounce = false;
              setTimeout(() => {
                this.allowDebounce = true;
              }, 1000)
              // console.log('curr ', curr);
              let top = curr.bottom;
              top = curr.index === 6 ? top + footer.clientHeight + 30 : top;
              // console.log('top ', top);
              content.style.top = -top + 'px';
              productsBackground.style.top = -top + 'px';
              window.scrollTo({
                top: top,
                behavior: 'instant'
              })
              this.currentSection = this.sections.find(c => newVal >= c.top && newVal < c.bottom)
              this.preventScroll();
            }
          }
        }
      }
    },
    resized(val) {
      if(val) {
        // this.isLoading = true;
        // this.isLoading = true;
      }
    }
  },
  computed: {
    largeSection() {
      return this.sections.find(s => s.height > window.innerHeight)
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    toggleMenu(val) {
      this.isMenuOpen = val;
    },
    preventScroll() {
      document.documentElement.classList.add('no-scroll');
      setTimeout(() => {
        document.documentElement.classList.remove('no-scroll');
      }, 500)
    },
    triggerNavigating() {
      this.navigating = true;
      setTimeout(() => {
        this.navigating = false;
      }, 1200)
    },
    initAnimatingSections() {
      // this.isSectionsSet = true;
      let ids = ['about', 'wwd', 'aviation', 'gse', 'raw-chemicals', 'consulting', 'contacts']
      let elements = ids.map(i => document.getElementById(i));
      let overallTop = 0;

      //Define sections
      setTimeout(() => {
        this.sections = elements.map((el, index) => {
          let section = {
            index: index + 1,
            id: el.id,
            height: el.clientHeight,
            triggerPoint: overallTop + el.clientHeight / 2,
            bottom: el.clientHeight + overallTop,
            top: overallTop,
          }
          if (index === 1) {
            let descendant = document.querySelector(`#aviation .product-flex`);
            let content = document.querySelector(`#gse .product-flex`);
            var sectionComputedStyles = descendant ? window.getComputedStyle(descendant) : 'empty';
            var contentComputedStyles = content ? window.getComputedStyle(content) : 'empty';
            let sectionMarginTop = +sectionComputedStyles.marginTop?.slice(0, -2);
            let contentMarginTop = +contentComputedStyles.marginTop?.slice(0, -2);
            section.bottom = section.bottom - contentMarginTop + sectionMarginTop;
          }
          overallTop += section.height;
          return section;
        });
        // console.log('sections ', this.sections);
      }, 500)


      //Set height to wrapper
      let content = this.$refs['main-content'];
      let rapper = document.getElementById('rapper-wrapper');
      let blank = document.getElementById('blank');
      let productsBackground = document.getElementById('products-background');
      setTimeout(() => {
        let height = content.clientHeight;
        rapper.style.height = height + 'px';
        productsBackground.style.opacity = 1;
        blank.style.height = this.sections.find(s => s.id === 'aviation').top + 'px';
        this.isLoading = false;
      }, 600)

      //Get scroll position
      onscroll = () => {
        // e.preventDefault();
        this.scrollPos = Math.floor(window.scrollY);
      }
    },
    cancelDebounce() {
      if (this.debouncedFunction) {
        this.debouncedFunction.cancel();
        this.debouncedFunction = null;
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/animations.scss";

.no-margin {
  display: none;
}

@media only screen and (max-width: 680px) {
  .container {
    padding-left: calc(7vw - 6px) !important;
    padding-right: calc(7vw - 6px) !important;
  }
}

@media only screen and (min-width: 680px) and (max-width: 1366px) {
  .container {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
    transform: scale(1.05);
    transform-origin: left;
  }
}

@media only screen and (min-width: 1366px) {
  .blurred {
    filter: none !important;
  }

  .no-margin {
    display: inline-block;
    user-select: none;
    margin-top: -5px;
    overflow: hidden;
  }

}

.isLoading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background: #4040411c;
  top:0;
}

.test {
  width: 100vw;
  height: 100vh;
  background: rgb(236, 255, 255);
  display: flex;
}

.left,
.right,
.middle {
  width: 50px;
  height: 50px;
}

.left {
  background: rgb(167, 216, 43);
}

.middle {
  background: rgb(192, 68, 68);
  margin-left: auto;
  margin-top: 30vh;
  width: 400px;
  height: 500px;
  // margin-right: auto;
}

.right {
  background: rgb(43, 182, 216);
  // margin-top: auto;
  margin-left: auto;
}

#main-content {
  position: fixed;
  top: 0;
  transition: top 1s ease-in-out;
  z-index: 2;
}

#products-background {
  position: fixed;
  width: 100vw;
  top: 0;
  opacity: 0;
  transition: top 1s ease-in-out;
}

#blank {
  width: 100vw;
  height: 0;
}

//TEMP
// #main-content {
//   background: #545353 !important;
// }
// .head-wrapper {
//   background: #545353 !important;
// }

html {
  width: 100vw !important;
  //TEMP
  // filter: contrast(0.8) brightness(0.4);
  overflow-x: hidden;
  scrollbar-width: none;
  scrollbar-color: #404041 transparent;
}

body::-webkit-scrollbar {
  width: 0px;
}

//TEMP
// body::-webkit-scrollbar-track{
//   background: #292828;
// }

body::-webkit-scrollbar-thumb {
  background-color: #404041;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

.fixed {
  position: fixed;
  // height: 100vh;
}

.container {
  // transition: all 1s ease;

}

a {
  text-decoration: none !important;
}

.blurred {
  filter: blur(7.5px) saturate(250%);
}

//TRANSITION
// .slide-enter-active {
//   transition: transform 0.5s ease;
// }

// .slide-leave-active {
//   transition: transform 0.5s ease;
// }

// .slide-enter {
//   transform: translateY(100%);
// }

// .slide-leave-to {
//   transform: translateY(-100%);
// }
//REVERSE TRANSITION
// .slide-reverse-enter-active {
//   transition: transform 0.5s ease;
// }

// .slide-reverse-leave-active {
//   transition: transform 0.5s ease;
// }

// .slide-reverse-enter {
//   transform: translateY(-100%);
// }

// .slide-reverse-leave-to {
//   transform: translateY(100%);
// }
</style>