<template>
    <Product :animating="isActive == 5" :onlyContent="onlyContent" bgColor="#D1F1AA" color="#96DC40" :categoryTag="categoryTag">
        <template v-slot:image>
            <img class="chat-box" src="../../../assets/images/dialogue box/1920/dialogue box.png" alt="">
        </template>
        <template v-slot:arrow>
            <img src="../../../assets/images/arrow-green.svg" alt="" class="arrow" />
        </template>
    </Product>
</template>

<script>
import Product from './Product';

export default {
    name: 'Consulting',
    components: {
        Product,
    },
    props: {
        onlyContent: Boolean,
        isActive: Number,
    },
    data() {
        return {
            categoryTag: "cons_serv",
        }
    }
}
</script>

<style >
.chat-box {
    width: calc(100vw - 116px);
    max-width: 470px;
    margin-top: 44px;
    margin-left: 61px;
}

@media only screen and (min-width: 1366px) {
    .chat-box {
        width: 427px;
        max-width: none;
        margin-top: 134px;
        margin-left: 100px;
    }
}

@media only screen and (min-width: 1920px) {
    .chat-box {
        width: 729px;
        margin-top: 124px;
        margin-left: 102px;
    }
}
</style>