<template>
  <Product :animating="isActive == 2" :onlyContent="onlyContent" bgColor="#BFD9FF" color="#5795F8" :categoryTag="categoryTag">
    <template v-slot:image>
      <img class="airplane" src="../../../assets/images/airplane/1920/airplane.png" alt="" />
    </template>
    <template v-slot:arrow>
      <img src="../../../assets/images/arrow-blue.svg" alt="" class="arrow" />
    </template>
  </Product>
</template>

<script>
import Product from './Product';

export default {
  name: 'AviationProducts',
  components: {
    Product,
  },
  props: {
    onlyContent: Boolean,
    isActive: Number,
  },
  data() {
    return {
      categoryTag: 'avi_prod',
    }
  }
}
</script>

<style lang="scss" scoped>
.airplane {
  width: calc(100vw - 16px);
  max-width: 660px;
  padding-top: 15%;
  margin-left: -20px;
  // animation-delay: 0.5s;
}

::v-deep .product-flex {
  // margin-top: calc((100vh - 370px)/2) !important;
  margin-top: 0 !important;
}

@media only screen and (min-width: 1366px) {
  ::v-deep .product-flex {
    // margin-top: calc((100vh - 370px)/2) !important;
    margin-top: -90px !important;
  }

  .airplane {
    width: 752px;
    padding-top: 45px;
    margin-left: -56px;
  }
}

@media only screen and (min-width: 1700px) {
  ::v-deep .product-flex {
    margin-top: -70px !important;
  }
}

@media only screen and (min-width: 1920px) {
  ::v-deep .product-flex {
    // margin-top: calc((100vh - 370px)/2) !important;
    margin-top: -140px !important;
  }

  .airplane {
    width: 1102px;
    padding-top: 51px;
    margin-left: -69px;
    max-width: none;
  }
}
</style>