<template>
    <div class="container wwd">
        <div class="row">
            <div class="col-12 wwd-wrapper">
                <div :class="{ animating: isActive == 1 }" class="wwd-title">
                    {{ $t('title_wwd') }}
                </div>
                <div class="normal-text">
                    <div class="wwd-text">
                        {{ $t('wwd_text') }}
                    </div>
                </div>
                <div class="wwd-text">
                    <div v-for="l, i in lines" :key="i" class="wwd-text no-margin" :class="{['line-' + i] : true, animating: isActive == 1 }" >
                        <span :class="'text-' + i">{{
                            $t('wwd_text') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhatWeDo',
    mounted() {
        this.createLines();
    },
    props: {
        isActive: Number,
    },
    data() {
        return {
            lines: [],
        }
    },
    methods: {
        createLines() {
            for (let i = 0; i <= 4; i++) {
                this.lines.push(i)
            }
        }
    },
}
</script>

<style lang="scss">
@import "@/scss/mixins/text-mixins.scss";

.wwd-title {
    display: inline-block;
    font-family: "ABCNormal-Light";
    font-size: 30px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.02rem;
    margin-top: 156px;
    color: #A9A6AD;
}

.wwd-text {
    display: inline-block;
    font-family: "BlackerProDisplay-Light";
    // width: 322px !important;
    font-style: normal;
    font-weight: 200;
    font-size: 30px;
    line-height: 40px;
    text-align: start;
    margin-top: 20px;
    position: relative;
    // margin-bottom: 12px;
}

@media only screen and (min-width: 1366px) {
    .wwd-wrapper {
        display: flex;
        align-items: start;
    }

    .wwd {
        // margin-top: 206px !important;
        height: 100vh;
        display: flex;
        align-items: center;
    }

    .wwd-title {
        width: 296px;
        margin-top: -10px;
        margin-right: 160px;
        animation-delay: 0.5s;
        animation-duration: 1s !important;
        //Font
        font-size: 40px;
        line-height: 48px;
    }

    .wwd-text {
        width: 641px;
        margin-top: 0;
        //Font
        font-size: 40px;
        line-height: 50px;

        &.no-margin {
            height: 50px;
        }
    }


    @for $i from 0 through 10 {
        .wwd-text .text-#{$i} {
            @include line-styles($i, 50)
        }
    }

    @for $i from 0 through 10 {
        .wwd-text .line-#{$i} {
            @include lineAnimation(0.6, $i);
        }
    }
}

@media only screen and (min-width: 1700px) {
    .wwd-title {
        margin-right: 220px;
    }
}

@media only screen and (min-width: 1920px) {
    .wwd {
        // margin-top: 226px !important;
    }

    .wwd-title {
        width: 352px;
        margin-top: -10px;
        margin-right: 328px;
        //Font
        font-size: 50px;
        line-height: 65px;
    }

    .wwd-text {
        width: 834px;
        //Font
        font-size: 50px;
        line-height: 60px;

        &.no-margin {
            &.wwd-text {
                height: 60px;
            }
        }
    }

    @for $i from 0 through 10 {
        .wwd-text .text-#{$i} {
            position: absolute;
            left: 0;
            @include line-styles($i, 60)
        }
    }
}
</style>