<template>
    <div class="container footer">
        <div class="record">
            © {{ year }} — All Rights Reserved
        </div>
        <div class="authority">
            <div class="author designed">
                Designed at <a href="https://duolution.com" class="dark">duolution studio</a>
            </div>
            <div class="author developed">
                Developed by <a href="https://www.linkedin.com/in/fuad-isayev/"  class="dark">Fuad Isayev</a >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        year() {
            return new Date().getFullYear();
        }
    },
}
</script>

<style lang="scss">
.record {
    width: 166px;
    
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    //Font
    font-family: "ABCNormal";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02rem;
    color: #A9A6AD;
}

.author {
    //Font
    font-family: "ABCNormal";
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02rem;
    color: #A9A6AD;

    .dark {
        color: #0F0F10;
        font-size: 14px;
    }

    &.designed {
        margin-top: 100px;
    }

    &.developed {
        margin-top: 15px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 680px) and (max-width: 1366px) {
    .record {
        margin-top: 30px;
    }   
}

@media only screen and (min-width: 1366px) {
    .footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        // margin-top: 192px;
        // margin-top: calc(18vh - 24px);
        height: 10vh;
    }

    .authority {
        display: flex;
    }

    .author {
        //Font
        font-size: 14px;
        line-height: 21px;

        &.designed {
            margin-top: 0;
            margin-right: 30px;
        }

        &.developed {
            margin-top: 0;
            margin-bottom: 3vh;
        }
    }

    .record {
        //Font
        font-size: 14px;
        line-height: 21px;
        margin-left: auto;
        margin-right: 0;
        width: fit-content;
        margin-top: 0;
    }

    .dark {
        display: inline-block;
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            background: #0F0F10;
            height: 1px;
            width: 0;
            margin-left: auto;
            transition: width 0.3s;

        }

        &:hover::after {
            width: 100%;
        }
    }   
}

@media only screen and (min-width: 1920px) {
    .author {
        //Font
        font-size: 16px;
        .dark {
        font-size: 16px;
    }
    }
    .record {
        font-size: 16px;
    }

    
}
</style>