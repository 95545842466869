<template>
    <div class="container-fluid">
        <img class="hero1 desktop" :class="{animating}" src="../../../assets/images/hero/1920/hero image@3x.png" alt="">
        <img class="hero1 mobile" src="../../../assets/images/hero/mobile/hero image@3x.png" alt="">
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: {
        animating: Boolean,
    }
}
</script>

<style lang="scss">
.hero1 {
    width: 100vw;
    line-height: 0;

    &.mobile {
        margin-top: 97px !important;
    }
    animation-delay: 0.6s;
}

.desktop {
    display: none;
    margin-top: 106px;
}

.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media only screen and (min-width : 680px) {
    .desktop {
        display: block;
        margin-top: 0;
    }

    .mobile {
        display: none;
    }

    @media only screen and (max-width: 1366px) {
        .hero1 {
            margin-top: 150px;
        }
    }
}
</style>