import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./vuex/store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(VueCookies);

new Vue({
    render: (h) => h(App),
    router,
    vuetify,
    i18n,
    store,
}).$mount("#app");
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./scss/main.scss";
