<template>
    <div>
        <v-container v-if="user">
            <v-row class="justify-space-between text-center">
                <v-col cols="2"><v-btn to="/" color="primary">Home</v-btn></v-col>
                <v-col cols="2"><v-btn @click="signOutUser">
                        Log out
                    </v-btn></v-col>
            </v-row>
            <v-row class="text-center">
                <v-col cols="2" class="mt-12">
                    <v-btn :to="'/admin/' + route.path" block class="mb-2" v-for="route, i in routes" :key="i">
                        {{ route.name }}
                    </v-btn>
                </v-col>
                <v-col class="ps-6" cols="10">
                    <div>
                        <router-view></router-view>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog persistent v-model="showLogin" width="360">
            <template>
                <Auth ref="auth" />
                <v-col class="white mt-0 pt-0">
                    <v-btn block to="/">Cancel</v-btn>
                </v-col>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import Auth from '../../components/dashboard/auth/Auth';
import { adminRoutes } from '@/router';

export default {
    name: 'AdminPanel',
    components: {
        Auth,
    },
    data() {
        return {
            showLogin: false,
        }
    },
    computed: {
        routes() {
            return this.user.isAdmin ? adminRoutes : adminRoutes.filter(r => r.path !== "admins");
        },
        user() {
            return this.$store.getters["auth/getUser"];
        }
    },
    async mounted() {
        await this.$store.dispatch("auth/checkUser");
        this.showLogin = !this.user;
    },
    methods: {
        signOutUser() {
            this.$store.dispatch("auth/signOutUser")
            this.$refs.auth?.$refs.form.reset();
        },
    },
    watch: {
        user(val) {
            this.showLogin = !val;
        }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 1366px) {
    div>.container {
        max-width: 1200px;
    }
}
</style>