import axios from "axios";

const APIClient = axios.create({
    baseURL: "https://api.aerosure.az/api/mail",
});

export default {
    send(payload) {
        return APIClient.post("/send", payload)
    }
}