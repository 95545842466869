import axios from "axios";
import cookies from "vue-cookies";

const user = cookies.get("user");

const APIClient = axios.create({
    baseURL: "https://aerosure1-default-rtdb.firebaseio.com/categories",
    headers: {
        "Content-type": "application/json",
    },
});

export default {
    getAll() {
        return APIClient.get(".json");
    },

    getSingle(tag) {
        return APIClient.get(`/.json?orderBy="tag"&equalTo="${tag}"`);
    },

    create(data) {
        return APIClient.post(`.json?auth=${user.token}`, data);
    },

    update(payload) {
        return APIClient.put(`/${payload.id}.json?auth=${user.token}`,payload.data);
    },

    delete(id) {
        return APIClient.delete(`/${id}.json?auth=${user.token}`);
    },
};
