import categoriesAPI from "@/API/endpoints/categories";
import Vue from "vue";

const state = {
    categories: [],
};

const getters = {
    getCategories: (state) => state.categories,
};

const mutations = {
    setCategories(state, payload) {
        state.categories = payload.sort((a, b) => a.id - b.id);
    },
};

const actions = {
    async fetchCategories({ commit, rootGetters }) {
        const res = await categoriesAPI.getAll();
        const categories = Object.values(res.data);
        const translations = rootGetters["translations/getTranslations"];
        // const categories = translations.filter((translation) => {
        //   return Object.values(res.data).some(
        //       (cat) => cat.tag === translation.tag
        //   )
        // });
        const filteredCategories = translations
            .map((translation) => {
                const category = categories.find(
                    (category) => category.tag === translation.tag
                );
                return category ? { ...translation, id: category.id } : null;
            })
            .filter((translation) => translation !== null);
        commit("setCategories", filteredCategories);
    },
    async createCategory(context, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Creating Category...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                await categoriesAPI.create(payload);
                Vue.swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                    title: "Success!",
                    text: "Category has been created!",
                });
                // dispatch("fetchCategories");
            },
        });
    },
    async updateCategory({ dispatch }, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Editing Category...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                const res = await categoriesAPI.getSingle(payload.tag);
                const id = Object.keys(res.data)[0];
                await categoriesAPI.update({
                    data: JSON.stringify(payload.id),
                    id: id + "/id",
                });
                Vue.swal.fire({
                    position: "top-right",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                    title: "Success!",
                    text: "Category has been updated!",
                });
                dispatch("fetchCategories");
            },
        });
    },
    async deleteCategory({ dispatch }, payload) {
        Vue.swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f00",
                cancelButtonColor: "#999",
                confirmButtonText: "Yes, delete it!",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    await categoriesAPI.delete(payload);
                    Vue.swal.fire({
                        position: "top-right",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                        title: "Deleted",
                        text: "Category has been deleted",
                    });
                    dispatch("fetchCategories");
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
