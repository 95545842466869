import adminsAPI from "@/API/endpoints/admins";
import Vue from "vue";

const state = {
  admins: [],
};

const getters = {
  getAdmins: (state) => state.admins,
};

const mutations = {
  setAdmins(state, payload) {
    state.admins = payload;
  },
};

const actions = {
  async fetchAdmins({ commit, rootGetters }) {
    const user = rootGetters["auth/getUser"];
    const res = await adminsAPI.getAll(user);
    commit("setAdmins", res.data);
  },
  async createAdmin({ dispatch }, payload) {
    Vue.swal.fire({
      icon: "loading",
      title: "Creating Admin...",
      width: "300px",
      didOpen: async () => {
        Vue.swal.showLoading();
        await adminsAPI.create(payload);
        Vue.swal.fire({
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          title: "Success!",
          text: "Admin has been created!",
        });
        dispatch("fetchAdmins");
      },
    });
  },
  async updateAdmin({ dispatch }, payload) {
    Vue.swal.fire({
      icon: "loading",
      title: "Editing Admin...",
      width: "300px",
      didOpen: async () => {
        Vue.swal.showLoading();
        await adminsAPI.update(payload);
        Vue.swal.fire({
          position: "top-right",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          title: "Success!",
          text: "Admin has been renamed!",
        });
        dispatch("fetchAdmins");
      },
    });
  },
  async deleteAdmin({ dispatch }, payload) {
    Vue.swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f00",
        cancelButtonColor: "#999",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await adminsAPI.delete(payload);
          Vue.swal.fire({
            position: "top-right",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            title: "Deleted",
            text: "Admin has been deleted",
          });
          dispatch("fetchAdmins");
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
