import Vue from "vue";
import VueI18n from "vue-i18n";
// import en from "../translations/en";
// import az from "../translations/az";
// import ru from "../translations/ru";

Vue.use(VueI18n);

// const messages = {en,az,ru}
const messages = {}

// function loadLocaleMessages() {
//     const locales = require.context(
//         "./translations",
//         true,
//         /[A-Za-z0-9-_,\s]+\.json$/i
//     );
//     const messages = {};
//     locales.keys().forEach((key) => {
//         const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//         if (matched && matched.length > 1) {
//             const locale = matched[1];
//             messages[locale] = locales(key);
//         }
//     });
//     return messages;
// }

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "en",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: messages,
});
