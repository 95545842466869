<template>
    <v-row class="my-12">
        <v-col class="text-start mb-5 pt-0 pl-0" cols="12">
            <v-btn @click="showCreate = !showCreate" color="primary">Add new Category</v-btn>
        </v-col>
        <v-dialog v-model="showCreate" width="540">
            <template>
                <CreateCategories v-if="showCreate" @close="showCreate = !showCreate" />
            </template>
        </v-dialog>
        <v-col class="table">
            <v-row class="pt-0 table-row">
                <v-col cols="1" class="table-cell">ID</v-col>
                <v-col cols="2" class="table-cell">Tag</v-col>
                <v-col class="table-cell">EN</v-col>
                <v-col class="table-cell">AZ</v-col>
                <v-col class="table-cell">RU</v-col>
            </v-row>
            <v-row class="pt-0 table-row" v-for="translation, i in categories" :key=i>
                <v-col @click="selectID(translation.tag, translation.id)" cols="1" class="table-cell">
                    <p class="editable"> {{ translation.id || "N/A" }}</p>
                </v-col>
                <v-col cols="2" class="table-cell">{{ translation.tag }}</v-col>
                <v-col @click="selecectText(translation.tag, 'en', translation.en)" class="table-cell">
                    <p class="editable line-clamp-2">{{ translation.en }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'az', translation.az)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.az }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'ru', translation.ru)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.ru }}</p>
                </v-col>
            </v-row>
            <v-dialog v-model="showUpdate" width="540">
                <template>
                    <v-col class="white mt-0 pt-0 text-center">
                        <v-form @submit.prevent="updateTranslation">
                            <h2 class="my-3 pt-2 ">Edit Category</h2>
                            <v-textarea ref="editInput" solo dense v-model="toBeUpdated.text" required
                                label="Enter text"></v-textarea>
                            <v-btn color="success" type="submit">Submit</v-btn>
                            <v-btn class="ml-3" @click="showUpdate = false">Cancel</v-btn>
                        </v-form>
                    </v-col>
                </template>
            </v-dialog>
            <v-dialog v-model="showUpdateCat" width="540">
                <template>
                    <v-col class="white mt-0 pt-0 text-center">
                        <v-form @submit.prevent="updateCat">
                            <h2 class="my-3 pt-2 ">Edit ID</h2>
                            <v-textarea ref="editCatInput" solo dense v-model="toBeUpdatedCat.id" required
                                label="Enter text"></v-textarea>
                            <v-btn color="success" type="submit">Submit</v-btn>
                            <v-btn class="ml-3" @click="showUpdateCat = false">Cancel</v-btn>
                        </v-form>
                    </v-col>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import CreateCategories from './CreateCategories';

export default {
    name: "Categories",
    data() {
        return {
            showCreate: false,
            showUpdate: false,
            showUpdateCat: false,
            toBeUpdatedCat: { tag: '', id: '' },
            toBeUpdated: { tag: '', lang: '', text: '' },
        }
    },
    components: {
        CreateCategories,
    },
    mounted() {
        // this.$store.dispatch("categories/fetchCategories")
    },
    computed: {
        categories() {
            return this.$store.getters["categories/getCategories"];
        },
        translations() {
            return this.$store.getters["translations/getTranslations"];
        }
    },
    methods: {
        selecectText(tag, lang, text) {
            this.showUpdate = true;
            setTimeout(() => {
                this.$refs.editInput.focus();
            }, 10)
            this.toBeUpdated = { tag, lang, text };
        },
        selectID(tag, id) {
            this.showUpdateCat = true;
            setTimeout(() => {
                this.$refs.editCatInput.focus();
            }, 10);
            this.toBeUpdatedCat = { tag, id }
        },
        updateTranslation() {
            this.$store.dispatch("translations/updateTranslation", this.toBeUpdated);
            this.showUpdate = false;
        },
        updateCat() {
            this.$store.dispatch("categories/updateCategory", this.toBeUpdatedCat)
            this.showUpdateCat = false;
        },
        saveTranslations() {
            this.$store.dispatch("translations/saveTranslations")
        }
    },
    watch: {
        translations() {
            this.$store.dispatch("categories/fetchCategories");
        }
    }
}
</script>

<style></style>