<template>
    <v-row class="my-12">
        <v-row class="justify-space-between mb-5 pt-0 pl-0 pe-0">
            <v-col v-if="selectedCategory" class="ps-0">
                <v-btn @click="showCreate = !showCreate" color="primary">Add new Subcategory</v-btn>
            </v-col>
            <v-col class="text-end pe-0" style="position: relative;">
                <span class="me-5">CATEGORY: </span>
                <v-btn id="but" :class="!selectedCategory && !showDropBox ? 'anim' : ''"
                    :color="selectedCategory ? 'primary' : ''" @click="showDropBox = !showDropBox">
                    {{ selectedCategory ? selectedCategory.en : "Choose Category" }}
                </v-btn>
                <div class="dropBox" v-if="showDropBox">
                    <div v-for="cat, i in categories" :key="i">
                        <div @click="selectCategory(cat)" class="text-center drop-element" small>{{ cat.en }}</div>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="showCreate" width="540">
            <template>
                <CreateSubcategories :category="selectedCategory" @close="showCreate = !showCreate" />
            </template>
        </v-dialog>
        <v-col class="table">
            <v-row class="pt-0 table-row">
                <v-col cols="1" class="table-cell">ID</v-col>
                <v-col cols="2" class="table-cell">Tag</v-col>
                <v-col class="table-cell">EN</v-col>
                <v-col class="table-cell">AZ</v-col>
                <v-col class="table-cell">RU</v-col>
                <v-col cols="2" class="table-cell">Delete</v-col>
            </v-row>
            <v-row class="pt-0 table-row" v-for="translation, i in filteredSubcategories" :key=i>
                <v-col @click="selectID(translation.tag, translation.id)" cols="1" class="table-cell">
                    <p class="editable"> {{ translation.id || "N/A" }} </p>
                </v-col>
                <v-col cols="2" class="table-cell">{{ translation.tag }}</v-col>
                <v-col @click="selecectText(translation.tag, 'en', translation.en)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.en }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'az', translation.az)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.az }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'ru', translation.ru)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.ru }}</p>
                </v-col>
                <v-col class="table-cell" cols="2">
                    <v-btn @click="deleteSubcategory(translation.tag)" color="error">DELETE</v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="showUpdate" width="540">
                <template>
                    <v-col class="white mt-0 pt-0 text-center">
                        <v-form @submit.prevent="updateTranslation">
                            <h2 class="my-3 pt-2 ">Edit Category</h2>
                            <v-textarea ref="editInput" solo dense v-model="toBeUpdated.text" required
                                label="Enter text"></v-textarea>
                            <v-btn color="success" type="submit">Submit</v-btn>
                            <v-btn class="ml-3" @click="showUpdate = false">Cancel</v-btn>
                        </v-form>
                    </v-col>
                </template>
            </v-dialog>
            <v-dialog v-model="showUpdateSubcat" width="340">
                <template>
                    <v-col class="white mt-0 pt-0 text-center">
                        <v-form @submit.prevent="updateSubcat">
                            <h2 class="my-3 pt-2 ">Edit ID</h2>
                            <v-text-field ref="editSubcatInput" solo dense v-model="toBeUpdatedSubcat.id" required
                                label="Enter text"></v-text-field>
                            <v-btn color="success" type="submit">Submit</v-btn>
                            <v-btn class="ml-3" @click="showUpdateSubcat = false">Cancel</v-btn>
                        </v-form>
                    </v-col>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import CreateSubcategories from './CreateSubcategories';

export default {
    name: 'Subcategories',
    data() {
        return {
            showCreate: false,
            showUpdate: false,
            showUpdateSubcat: false,
            toBeUpdated: { tag: '', lang: '', text: '' },
            toBeUpdatedSubcat: { tag: '', id: '' },
            selectedCategory: null,
            showDropBox: false,
        }
    },
    components: {
        CreateSubcategories,
    },
    mounted() {
        // this.$store.dispatch("categories/fetchCategories");
        // this.$store.dispatch("subcategories/fetchSubcategories");
    },
    computed: {
        translations() {
            return this.$store.getters["translations/getTranslations"];
        },
        categories() {
            return this.$store.getters["categories/getCategories"];
        },
        subcategories() {
            return this.$store.getters["subcategories/getSubcategories"];
        },
        filteredSubcategories() {
            return this.subcategories.filter((sub) => sub.category === this.selectedCategory?.tag).sort((a, b) => a.id - b.id) || []
        },

    },
    methods: {
        selecectText(tag, lang, text) {
            this.showUpdate = true;
            setTimeout(() => {
                this.$refs.editInput.focus();
            }, 10);
            this.toBeUpdated = { tag, lang, text };
        },
        selectID(tag, id) {
            this.showUpdateSubcat = true;
            setTimeout(() => {
                this.$refs.editSubcatInput.focus();
            }, 10);
            this.toBeUpdatedSubcat = { tag, id }
        },
        updateTranslation() {
            this.$store.dispatch("translations/updateTranslation", this.toBeUpdated);
            this.showUpdate = false;
        },
        updateSubcat() {
            this.$store.dispatch("subcategories/updateSubcategory", this.toBeUpdatedSubcat)
            this.showUpdateSubcat = false;
        },
        saveTranslations() {
            this.$store.dispatch("translations/saveTranslations")
        },
        selectCategory(cat) {
            this.selectedCategory = cat;
            this.showDropBox = false;
        },
        async deleteSubcategory(tag) {
            await this.$store.dispatch("subcategories/deleteSubcategory", tag);
        }
    },
    watch: {
        translations() {
            this.$store.dispatch("subcategories/fetchSubcategories");
        }
    }
}
</script>

<style lang="scss">
.dropBox {
    position: absolute;
    right: 0;
    top: 60px;
    border: 1px solid cyan;
    z-index: 3;
}

.drop-element {
    padding: 5px;
    background: #e7ffff;
    border: 1px solid cyan;
    cursor: pointer;

    &:hover {
        background: rgb(117, 173, 245);
        color: white;
    }
}

.v-btn.anim {
    animation-name: clickMe;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-fill-mode: both;
    // background: green !important;
}

@keyframes clickMe {
    0% {
        background: #f5f5f5;
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
        background: #fff9f9;
        box-shadow: 0px 3px 1px -2px rgba(0, 8, 255, 0.562), 0px 2px 2px 0px rgba(67, 177, 207, 0.71), 0px 1px 5px 0px rgba(18, 47, 233, 0.6);
        // color: rgb(255, 0, 0);
    }
}
</style>