import axios from "axios";
import cookies from "vue-cookies";

const user = cookies.get("user");

const APIClient = axios.create({
    baseURL: "https://api.aerosure.az/api/admins",
    headers: {
        'Authorization': user ? `Bearer ${user.token}` : "",
    },
});

export default {
    getAll(user) {
        return APIClient.get('/', {
            headers: {
                'Authorization': user ? `Bearer ${user.token}` : "",
            }
        });
    },

    getSingle(id) {
        return APIClient.get(`/${id}`);
    },

    create(data) {
        return APIClient.post('/', data);
    },

    update(data) {
        return APIClient.put('/', data);
    },

    delete(data) {
        const email = encodeURIComponent(data.email);
        return APIClient.delete(`/${email}`);
    },
};
