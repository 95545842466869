import axios from "axios";
import cookies from "vue-cookies";

const user = cookies.get("user");

const APIClient = axios.create({
    baseURL: "https://aerosure1-default-rtdb.firebaseio.com/translations",
});

const APIClient2 = axios.create({
    baseURL: "https://api.aerosure.az/api/translations",
    headers: {
        Authorization: user ? `Bearer ${user.token}` : "",
    },
});

export default {
    getAll() {
        return APIClient.get('.json');
    },

    getSingle(id) {
        return APIClient.get(`/${id}.json`);
    },

    create(data) {
        const tag = encodeURIComponent(data.tag);
        return APIClient.put(`/${data.lang}/${tag}.json?auth=${user.token}`, JSON.stringify(data.text));
    },

    update(data) {
        const tag = encodeURIComponent(data.tag);
        return APIClient.put(
            `/${data.lang}/${tag}.json?auth=${user.token}`,
            JSON.stringify(data.text)
        );
    },

    delete(data) {
        const tag = encodeURIComponent(data.tag);
        return APIClient.delete(
            `/${data.lang}/${tag}.json?auth=${user.token}`
        );
    },
    save(data) {
        // console.log("DATA ", data);
        return APIClient2.put('/save', data);
    }
};
