import translationsAPI from "@/API/endpoints/translations";
import Vue from "vue";
// import axios from "axios";
import i18n from "@/i18n";

const state = {
    translations: [],
    i18translations: {},
};

const getters = {
    getTranslations: (state) => state.translations,
    geti18Translations: (state) => state.i18translations,
};

const mutations = {
    setTranslations(state, payload) {
        state.i18translations = payload;
        i18n.setLocaleMessage('en', payload.en);
        i18n.setLocaleMessage('az', payload.az);
        i18n.setLocaleMessage('ru', payload.ru);
        const translations = Object.entries(state.i18translations.en).map(
            ([tag, en]) => ({
                tag,
                en,
                az: state.i18translations.az[tag],
                ru: state.i18translations.ru[tag],
            })
        );
        state.translations = translations.sort((a, b) => {
            if (a.tag < b.tag) {
                return -1;
            }
            if (a.tag > b.tag) {
                return 1;
            }
            return 0;
        });
    },
};

const actions = {
    async fetchTranslations({ commit }) {
        const res = await translationsAPI.getAll();
        commit("setTranslations", res.data);
    },
    async createTranslation({ dispatch }, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Creating Translation...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                await translationsAPI.create({
                    tag: payload.tag,
                    lang: "en",
                    text: payload.enText,
                });
                await translationsAPI.create({
                    tag: payload.tag,
                    lang: "az",
                    text: payload.azText,
                });
                await translationsAPI.create({
                    tag: payload.tag,
                    lang: "ru",
                    text: payload.ruText,
                });
                Vue.swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                    title: "Success!",
                    text: "Translation has been created!",
                });
                dispatch("fetchTranslations");
            },
        });
    },
    async updateTranslation({ state, commit }, payload) {
        Vue.swal.fire({
            icon: "loading",
            title: "Editing Translation...",
            width: "300px",
            didOpen: async () => {
                Vue.swal.showLoading();
                const res = await translationsAPI.update(payload);
                if (res.status === 200) {
                    const translations = state.i18translations;
                    const editedTranslations = {
                        ...translations,
                        [payload.lang]: {
                            ...translations[payload.lang],
                            [payload.tag]: payload.text,
                        },
                    };
                    commit("setTranslations", editedTranslations);
                    Vue.swal.fire({
                        position: "center",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                        title: "Success!",
                        text: "Translation has been updated!",
                    });
                }
                // dispatch("fetchTranslations");
            },
        });
    },
    async deleteTranslation({ dispatch }, payload) {
        Vue.swal
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#f00",
                cancelButtonColor: "#999",
                confirmButtonText: "Yes, delete it!",
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    Vue.swal.fire({
                        icon: "loading",
                        title: "Deleting Translation...",
                        width: "300px",
                        showConfirmButton: false,
                        didOpen: async() => {
                            Vue.swal.showLoading();
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "en",
                            });
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "az",
                            });
                            await translationsAPI.delete({
                                tag: payload,
                                lang: "ru",
                            });
                            Vue.swal.fire({
                                icon: "success",
                                showConfirmButton: false,
                                timer: 1000,
                                title: "Deleted",
                                text: "Translation has been deleted",
                            });
                            dispatch("fetchTranslations");
                        }
                    });
                }
            });
    },
    async saveTranslations({state}) {
        Vue.swal.fire({
            icon: "loading",
            title: "Saving Translations...",
            width: "300px",
            timer: 1500,
            didOpen: async () => {
                Vue.swal.showLoading();
                await translationsAPI.save(state.i18translations);
            }
        })
        
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
