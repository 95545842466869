<template>
  <div class="d-flex flex-column justify-space-between about">
    <div class="container">
      <div class="row justify-center">
        <div class="col-12 text-center">
          <div id="wrapper" class="about-text">
            <div class="normal-text">
              <span>{{ $t('about_us_text') }}</span>
            </div>
            <div>
              <div v-for="l, i in lines" :key="i" class="about-text no-margin" :class="{['line-' + i]: true, animating: !isMobile}">
                <span :class="'text-' + i">{{
                  $t('about_us_text') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Hero :animating="!isMobile"/>
  </div>
</template>

<script>
import Hero from './Hero';

export default {
  name: 'About',
  components: {
    Hero
  },
  mounted() {
    this.createLines();
  },
  data() {
    return {
      lines: [],
    }
  },
  computed: {
    isMobile(){
      return this.$store.getters.isMobile;
    }
  },
  methods: {
    createLines() {
      for (let i = 0; i <= 4; i++) {
        this.lines.push(i)
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/mixins/text-mixins.scss";

.about-text {
  display: inline-block;
  font-family: "BlackerProDisplay-Light";
  font-size: 20px;
  line-height: 25px;
  font-weight: 200;
  width: 322px;
  // margin-top: 69px;
  margin-top: 125px;
  color: #0F0F10;
}

@media only screen and (max-width: 360px) {
  .about-text {
    max-width: fit-content;
  }
}

@media only screen and (min-width: 680px) {
  .hero {
    width: 100vw;
    left: 0;
    margin-top: 118px;
  }

  .about-text {
    width: 400px;
    font-size: 25px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 1366px) {
  .about-text {
    margin-top: calc(22.66vh - 14px);
    width: 650px;
    // margin-top: 176px;
    //Font
    font-size: 30px;
    line-height: 40px;
    position: relative;

    &.no-margin {
      height: 40px;
    }
  }

  .normal-text {
    display: none;
  }

  .about {
    height: 100vh;
  }

  @for $i from 0 through 10 {
    .text-#{$i} {
      position: absolute;
      left: 0;
      @include line-styles($i, 40)
    }
  }

  @for $i from 0 through 10 {
    .line-#{$i} {
      @include lineAnimation(0, $i);
    }
  }
}

@media only screen and (min-width: 1920px) {
  .about-text {
    margin-top: calc(25.5vh - 24px);
    width: 960px;
    font-size: 40px;
    line-height: 50px;

    &.no-margin {
      height: 50px;
    }
  }

  @for $i from 0 through 10 {
    .about-text .text-#{$i} {
      @include line-styles($i, 50)
    }
  }
}
</style>