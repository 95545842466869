<template>
  <div>
    <div class="container-fluid head-wrapper">

    </div>
    <div class="container head" v-scroll-lock="menuOpen">
      <div class="row head">
        <div class="col-4 logo-wrapper">
          <img @click="goToLink('about')" class="logo" src="../../../assets/images/AEROSURE.svg" alt="">
        </div>
        <div class="col-8 all-buttons">
          <div class="row justify-end ">
            <div class="col-12 text-end">
              <div @click="goToLink('aviation')" class="nav-button products">{{ $t('button_products') }}</div>
              <div @click="goToLink('contacts')" class="nav-button contacts">{{ $t('button_contacts') }}</div>
              <div v-for="lang, i in selectableLangs " :key="i" @click="changeLang(lang)" class="lang-button"
                :class="lang.class"> {{ lang.name }}</div>
            </div>
          </div>
        </div>
        <div class="col-8 hamburger-wrapper text-end">
          <img @click="menuOpen = !menuOpen" v-if="!menuOpen" class="hamburger" src="../../../assets/images/hamburger.svg"
            alt="">
          <img @click="menuOpen = !menuOpen" v-else class="close" src="../../../assets/images/close.svg" alt="">
        </div>
        <transition name="background">
          <Menu @close="menuOpen = false" v-if="menuOpen" :langs="selectableLangs" @changeLang="changeLang" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/fonts.css";
import Menu from "./Menu";

export default {
  name: "Header",
  directives: {
    scrollLock: {
      update(el, binding) {
        if (binding.value) {
          document.documentElement.classList.add('no-scroll');
        } else {
          document.documentElement.classList.remove('no-scroll');
        }
      },
    },
  },
  components: {
    Menu
  },
  data() {
    return {
      langs: [
        { value: "az", name: "AZ", class: "first" },
        { value: "en", name: "EN" },
        { value: "ru", name: "RU", class: "second" },
      ],
      menuOpen: false,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    selectableLangs() {
      const langs = [...(this.langs.filter(lang => lang.value !== this.lang))];
      langs[0].class = "first";
      langs[1].class = "second";
      return langs;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  },
  watch: {
    menuOpen(val) {
      this.$emit('toggleMenu', val)
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang.value;
    },
    goToLink(link) {
      if(this.isMobile){
        window.location.href = `#${link}`;
      } else {
        this.scrollToLink(link);
      }
    },
    scrollToLink(link) {
      // this.preventBlockScroll();
      this.$emit('navigating');
      this.preventInstantTransition();
      let content = document.getElementById('main-content');
      let productsBackground = document.getElementById('products-background');

      let sections = this.$parent.sections;
      let element = sections.find(s => s.id === link);
      let previousElement = sections.find(s => s.index === element.index - 1);
      // console.log(previousElement, 'aa');
      let footer = document.getElementById('footer');
      // console.log(content);
      let top = 0;
      if(link !== 'about') {
        top = previousElement.bottom;
      }
      top = element.id === 'contacts' ? top + footer.clientHeight + 30 : top;
      content.style.top = -top + 'px';
      productsBackground.style.top = -top + 'px';
      window.scrollTo({
        top: top,
        behavior: 'instant'
      })
      this.blockScroll();
    },
    blockScroll() {
      document.documentElement.classList.add('no-scroll');
      setTimeout(() => {
        document.documentElement.classList.remove('no-scroll');
      }, 500)
    },
    preventBlockScroll() {
      document.documentElement.classList.add('forced-scroll');
      setTimeout(() => {
        document.documentElement.classList.remove('forced-scroll');
      }, 200)
    },
    preventInstantTransition() {
      document.getElementById('main-content').style.transition = 'top 1s ease-in-out';
      document.getElementById('products-background').style.transition = 'top 1s ease-in-out';
    }
  }
}
</script>

<style lang="scss">
.no-scroll {
  overflow-y: hidden;
}

.forced-scroll {
  overflow-y: visible !important;
}

.forced-transition {
  transition: top 1s ease-in-out !important;
}

.container {
  &.head {
    position: fixed;
    z-index: 10;
    background: #FFFFFFbb;
  }
}

.logo {
  width: 106px;
  height: 20px;
  // margin-left: -5px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.row {
  .hamburger {
    width: 40px !important;
    // height: 10px !important;
    cursor: pointer;
    margin-left: auto !important;
  }
}

.close {
  width: 30px;
  margin-right: 9px;
  margin-top: -9px;
  cursor: pointer;
}

.hamburger-wrapper {
  line-height: 8px !important;
}

.all-buttons {
  display: none !important;
  height: 20px;
  //Font
  font-family: "ABCNormal";
  font-size: 16px;
  letter-spacing: 0.03em;
}

.nav-button {
  display: inline-block;
  color: #0F0F10;
  cursor: pointer;

  &.products {
    // width: 166px;
    width: fit-content;
    margin-right: 30px;
  }

  &.contacts {
    // width: 91px;
    width: fit-content;
    margin-right: 60px;
  }
}

.lang-button {
  display: inline-block;
  color: #A9A6AD;
  cursor: pointer;
  width: 22px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &.first {
    margin-right: 20px;
  }

  &:hover {
    color: #0F0F10;
    font-weight: 500;
  }
}

@media only screen and (min-width: 680px) {
  .close {
    right: 10vw;
  }
}

@media only screen and (min-width: 1366px) {
  .head-wrapper {
    position: fixed;
    // background: #ffffffcc;
    height: 100px;
    z-index: 9;
  }

  .logo {
    width: 165px;
    // margin-top: 26px;
    margin-top: 8px;
  }

  .container {
    &.head {
      position: fixed;
      margin-left: calc((100vw - 1122px)/2);
      z-index: 10;
      padding-top: 18px;
      margin-top: 0;
      background: none;
      // background: #FFFFFFdd;
    }
  }

  .all-buttons {
    display: flex !important;
    margin-top: -7px !important;
  }

  .nav-button {
    &::after {
      content: "";
      display: block;
      background: #0F0F10;
      height: 1px;
      width: 100%;
      margin-left: auto;
      transition: width 0.3s;
    }

    &:hover::after {
      width: 0;
    }
  }

  .hamburger,
  .close {
    display: none;
  }
}

@media only screen and (min-width: 1700px) {
  .container {
    &.head {
      margin-left: calc((100vw - 1451px)/2);
    }
  }
}

@media only screen and (min-width: 1920px) {
  .head-wrapper {
    height: 119px;
  }

  .container {
    &.head {
      margin-left: calc((100vw - 1639px)/2);
      padding-top: 0;
      margin-top: 25px;
    }
  }

  .logo {
    width: 188px;
    height: 35px;
    margin-top: 8px;
  }

  .all-buttons {
    margin-top: 0 !important;
    //Font
    font-size: 20px;
    line-height: 24px;
  }

  .nav-button {
    &.contacts {
      margin-right: 80px;
    }
  }
}

.background-enter-active {
  transition: background-color 1s;
}

.background-enter {
  background: transparent !important;
}
</style>