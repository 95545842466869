<template>
  <div>
    <v-app>
      <v-main>
        <router-view :resized="resized" :i18nisLoaded="i18nisLoaded"></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import axios from "axios"; //Temporary changes

export default {
  name: 'App',
  data: () => ({
    i18nisLoaded: false,
    resized: false,
    //
  }),
  async beforeCreate() {
    await this.$store.dispatch("translations/fetchTranslations");
    this.i18nisLoaded = true;
    await this.$store.dispatch("categories/fetchCategories");
    await this.$store.dispatch("subcategories/fetchSubcategories");
    //Temporary changes
    await axios.get("https://api.aerosure.az")
  },
  mounted() {
    window.onresize = () => {
      this.$store.commit("setScreenWidth", window.innerWidth);
      this.resized = true;
      setTimeout(() => {
        this.resized = false;
      }, 1)

    }
  }
};
</script>
