<template>
    <div class="container touch">
        <div class="contacts-title">
            {{ $t('title_contacts') }}
        </div>
        <form class="contact-form" ref="form" @submit.prevent="submit">
            <div class="input-wrapper">
                <label v-if="name" for="name" class="input-label name-label">{{ $t('input_name') }}</label>
                <input minlength="1" maxlength="100" required id="name" v-model="name" class="input name" type="text"
                    :placeholder="$t('input_name')">
            </div>

            <div class="input-wrapper">
                <label v-if="contacts" for="contacts" class="input-label">{{ $t('input_contacts') }}</label>
                <input minlength="7" maxlength="256" required id="contacts" v-model="contacts" class="input" type="text"
                    :placeholder="$t('input_contacts')">
            </div>

            <div class="input-wrapper textarea-wrapper">
                <label v-if="message" for="message" class="input-label">{{ $t('input_message') }}</label>
                <textarea minlength="1" maxlength="1000" required :style="`height: ${textareaHeight}px`" ref="textarea"
                    v-model="message" class="message" name="message" id="message" :placeholder="$t('input_message')">
                </textarea>
                <div v-show="showScrollBar" ref="track" :style="`height: ${maxHeight - 45}px`" class="scrollbar-track">
                    <div ref="thumb" class="scrollbar-thumb"></div>
                </div>
            </div>
            <input required class="submit" type="submit" :value="$t('button_send')">
        </form>
        <transition name="fade">
            <div v-if="notification" :class="notificationClass">{{ notification }}</div>
        </transition>
        <div class="digits">
            <div class="digit-wrapper">
                <div @click="callPhoneNumber(number)" class="contact number">{{ number }}</div>
                <div @click="openEmail(email)" class="contact email">{{ email }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import mailAPI from "@/API/endpoints/mail";

export default {
    name: 'Contacts',
    data() {
        return {
            name: '',
            contacts: '',
            message: '',
            number: '+994 99 977 97 77',
            email: 'info@aerosure.az',
            notification: '',
            notificationClass: '',
            minHeight: 218, // Minimum height of the textarea
            maxHeight: 380, // Maximum height of the textarea
            textareaHeight: 220, // Initial height of the textarea
            showScrollBar: false,
            ratio: 1,
            maxThumbTop: null,
        }
    },
    props: {
        isActive: Number,
    },
    mounted() {
        this.$refs.textarea.addEventListener('scroll', () => {
            // let top = this.$refs.track.scrollTop * this.ratio;
            // if(top > this.maxThumbTop) {
            //     top = this.maxThumbTop
            // }
            // this.$refs.thumb.style.top = top + 'px';
            var thumbPosition = (this.$refs.textarea.scrollTop / this.$refs.textarea.scrollHeight) * this.$refs.track.offsetHeight;
            this.$refs.thumb.style.top = thumbPosition + "px";
        })
        var _this = this;
        this.$refs.thumb.addEventListener("mousedown", (event) => {
            event.preventDefault();
            var startY = event.clientY;
            var startScrollTop = this.$refs.textarea.scrollTop;

            document.addEventListener("mousemove", moveHandler);
            document.addEventListener("mouseup", upHandler);

            function moveHandler(event) {
                var delta = event.clientY - startY;
                var scrollPos = startScrollTop + (delta / _this.$refs.textarea.offsetHeight) * _this.$refs.textarea.scrollHeight;
                _this.$refs.textarea.scrollTop = scrollPos;
            }

            function upHandler() {
                document.removeEventListener("mousemove", moveHandler);
                document.removeEventListener("mouseup", upHandler);
            }
        });
    },
    methods: {
        submit() {
            if (this.$refs.form.checkValidity()) {
                this.sendEmail(this.email);
            } else {
                console.log('Form validation failed!');
            }
        },
        callPhoneNumber(number) {
            window.location.href = 'tel:' + number;
        },
        async sendEmail() {
            const mail = {
                name: this.name,
                email: this.contacts,
                message: this.message
            }
            try {
                const res = await mailAPI.send(mail);
                if (res.status === 200) {
                    this.$swal.fire({
                        icon: "success",
                        title: "Your message has been sent",
                        timer: 1500,
                        timerProgressBar: true,
                    })
                    this.resetForm();
                }
            } catch(error) {
                this.$swal.fire("Error!", "Something went wrong", "error")
                this.$swal.fire({
                    icon: "error",
                    title: "Something went wrong",
                    timer: 1500,
                    timerProgressBar: true,
                })
            }
        },
        openEmail(email) {
            window.location.href = 'mailto:' + email
        },
        resetForm() {
            this.name = '';
            this.contacts = '';
            this.message = '';
        }
    },
    watch: {
        message() {
            const textarea = this.$refs.textarea;

            if (this.message.length < 1) {
                // textarea.style.height = this.textareaHeight + 'px';
                // this.showScrollBar = false;
                // console.log('I am here');
            } else {
                textarea.style.height = 'auto';
                let newHeight = Math.max(textarea.scrollHeight, this.minHeight);
                newHeight = Math.min(newHeight, this.maxHeight);
                textarea.style.height = newHeight + 2 + 'px';
                this.ratio = ((textarea.style.height.slice(0, -2) - 2) / textarea.scrollHeight).toFixed(2);
                if (textarea.scrollHeight > this.maxHeight - 1) {
                    // console.log('I am also here');
                    this.showScrollBar = true;
                    let thumbHeight = this.maxHeight - 45;
                    this.$refs.thumb.style.height = thumbHeight * this.ratio + 'px';
                    this.maxThumbTop = thumbHeight - thumbHeight * this.ratio;
                } else {
                    this.showScrollBar = false;
                }
            }
        }
    },
    beforeDestroy() {
        this.$refs.textarea.removeEventListener('scroll', null);
    }
}
</script>

<style lang="scss">
@import "@/assets/fonts.css";

.touch {
    margin-top: 106px;
}

.contact-form {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center !important;
}

.contacts-title {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 216px;
    //Font
    font-family: "ABCNormal-Light";
    font-weight: 200;
    font-size: 30px;
    letter-spacing: 0.02rem;
    color: #A9A6AD;
}

.input {
    margin-top: 20px;
    border: 1px solid #6A676D;
    border-radius: 10px;
    width: 322px;
    height: 47px;
    padding-left: 15px;
    //Font
    font-family: "ABCNormal";
    font-size: 14px;
    line-height: 17px;

    &::placeholder {
        color: #A9A6AD;
    }

    &:focus {
        border: 1px solid #0F0F10 !important;
        outline: #0F0F10 solid 1px !important;
    }
}

.input-wrapper {
    position: relative;

    &.textarea-wrapper {
        &::before {
            content: '';
            display: block;
            height: 10px;
            background: #ffffff;
            position: absolute;
            left: 15px;
            right: 15px;
            top: 21px;
        }

        position: relative;
    }
}

.input-label {
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    left: 11px;
    top: 7px;
    background: white;
    animation-name: getsmall;
    animation-duration: 0.2s;

    &.name-label {
        top: -3px;
    }

    //Font
    font-family: "ABCNormal";
    font-size: 16px;
    text-align: start;
    color: #0F0F10;
}

.scrollbar-track {
    position: absolute;
    top: 42px;
    right: 10px;
    width: 4px;
    background: #EAE9ED;
    border-radius: 10px;

}

.scrollbar-thumb {
    position: absolute;
    right: 0;
    background: #7672FF;
    width: 4px;
    border-radius: 10px;

    // cursor: pointer;
    &:hover {
        background: #8e8afe;
    }

    &:active {
        background: #9f9cfd;
        transform: scaleX(1.15);
    }
}

.message {
    margin-top: 20px;
    border: 1px solid #6A676D;
    border-radius: 10px;
    width: 322px;
    max-height: 451px;
    resize: none;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    //Font
    font-family: "ABCNormal";
    font-size: 14px;
    line-height: 150%;


    &::placeholder {
        color: #A9A6AD;
    }

    //Scrollbar for chrome
    // &::-webkit-scrollbar-thumb {
    //     box-shadow: inset 0 0 10px 10px #7672FF;
    //     border: solid 5px transparent;
    //     border-width: 22px 5px;
    //     border-radius: 20px;
    // }

    &::-webkit-scrollbar {
        width: 0;
    }

    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 5px 10px #EAE9ED;
    //     border: solid 5px transparent;
    //     border-width: 22px 5px;
    //     border-radius: 20px;
    // }

    //Scrollbar for Firefox 
    // scrollbar-color: #7672FF #EAE9ED;
    scrollbar-width: none;

    &:focus {
        border: 1px solid #0F0F10 !important;
        outline: #0F0F10 solid 1px !important;
    }

    &::before {
        content: '';
        display: block;
        height: 10px;
        background: #1c1b1b;
        position: absolute;
        left: 4px;
        right: 18px;
    }
}

.submit {
    margin-top: 14px;
    background: #0F0F10;
    color: white;
    border-radius: 10px;
    width: 322px;
    height: 47px;
    //Font
    font-family: "ABCNormal";
    font-size: 14px;
    letter-spacing: 0.02rem;
    text-align: center;

    &:hover {
        background: #7672FF;
    }
}

.contact {
    font-family: "ABCNormal";
    font-size: 14px;
    line-height: 17px;
    color: #0F0F10;
    width: fit-content;
    text-align: left;
    height: 17px;
    // margin-left: auto;
    margin-right: auto;

    &.number {
        margin-top: 100px;
    }

    &.email {
        margin-top: 15px;
    }

    cursor: pointer;
}

.successMessage {
    position: absolute;
    bottom: 100px;
    left: 0px;
    height: 47px;
    width: 322px;
    // border: 1px solid green;
    border-radius: 10px;
    // background: rgb(156, 217, 156);
    display: flex;
    align-items: center;
    justify-content: center;
    //Font
    font-family: "ABCNormal";
    font-size: 20px;
    color: #96DC40;

    &.loading {
        background: transparent;
    }
}

.failMessage {
    position: absolute;
    margin-top: 492px;
    height: 47px;
    height: 40px;
    width: 322px;
    // border: 1px solid green;
    border-radius: 10px;
    // background: rgb(231, 155, 155);
    display: flex;
    align-items: center;
    justify-content: center;
    //Font
    font-family: "ABCNormal";
    font-size: 20px;
    color: red;
}

.digits {
    display: flex;
    justify-content: center;
}

.digit-wrapper {
    width: 166px;
}

@keyframes getsmall {
    0% {
        font-size: 18px;
        top: 24px;
        background: none;
    }

    100% {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {

    .input-wrapper,
    .input,
    .message,
    .submit,
    .successMessage,
    .failMessage {
        width: 100%;
    }
}

@media only screen and (min-width: 1366px) {
    .touch {
        display: flex;
        // margin-top: 226px;
        margin-top: 26.25vh;
        height: 80vh;
    }

    .contacts-title {
        margin-top: 0;
        // margin-right: 234px;
        // margin-right: auto;
        //Font
        font-size: 40px;
        line-height: 48px;
    }

    .contact-form {
        // margin-right: 54px;
        position: absolute;
        left: calc(55vw - 200px);
    }

    .input {
        &.name {
            margin-top: 10px;
        }

        width: 400px;
        height: 52px;
        //Font
        font-size: 18px;
    }

    .message {
        width: 400px;
        //Font
        font-size: 18px;
    }

    .submit {
        margin-top: 20px;
        width: 400px;
        height: 52px;
        //Font
        font-size: 18px;
    }

    .digits {
        // align-self: end;
        margin-left: auto;
        margin-top: auto;
    }

    .contact {
        font-size: 18px;
        line-height: 22px;
        width: fit-content;

        &.email {
            margin-top: 18px;
            margin-bottom: -10vh;
        }

        &.number {
            margin-top: 0;
        }

        &::after {
            content: "";
            display: block;
            background: #0F0F10;
            height: 1px;
            width: 100%;
            margin-left: auto;
            transition: width 0.3s;
        }

        &:hover::after {
            width: 0;
        }
    }

    .successMessage {
        width: 100%;
        height: 52px;
        margin-top: 560px;
    }
    .digit-wrapper {
        width: 192px;
        height: 60px;
    }
}

@media only screen and (min-width: 1700px) {
    .contact-form {
        margin-right: 300px;
    }

    .contacts-title {
        margin-right: 320px;
    }
}

@media only screen and (min-width: 1920px) {
    .contacts-title {
        margin-right: 410px;
        width: 270px;
        //Font
        font-size: 50px;
        line-height: 61px;
    }

    .contact-form {
        margin-right: 313px;
    }

    .input {
        &.name {
            margin-top: 20px;
        }

        height: 60px;
        //Font
        font-size: 20px;
    }

    .input-label {
        &.name-label {
            top: 7px;
        }
    }

    .message {
        //Font
        font-size: 20px;
    }

    .submit {
        height: 60px;
        //Font
        font-size: 20px;
    }

    .contact {
        font-size: 20px;
        line-height: 24px;
    }

    .successMessage {
        height: 60px;
        margin-top: 610px;
    }

    .digit-wrapper {
        width: 217px;
        height: 60px;
    }
}

//TRANSITION
.fade-enter-active,
.fade-leave-active {
    transition: all 1s ease;
}

.fade-enter {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}
</style>