<template>
    <Product :animating="isActive == 3" :splitIndex="2" :onlyContent="onlyContent" bgColor="#CFCEFF" color="#7672FF" :categoryTag="categoryTag">
        <template v-slot:image>
            <img class="car" src="../../../assets/images/car/1920/car.png" />
        </template>
        <template v-slot:arrow>
            <img src="../../../assets/images/arrow-purple.svg" alt="" class="arrow" />
        </template>
    </Product>
</template>

<script>
import Product from './Product';

export default {
    name: 'GSE',
    components: {
        Product,
    },
    props: {
        onlyContent: Boolean,
        isActive: Number,
    },
    data() {
        return {
            categoryTag: "gse",
        }
    }
}
</script>

<style lang="scss">
.car {
    width: calc(100vw - 107px);
    max-width: 460px;
    position: absolute;
    left: 42px;
    top: -18px;
}

@media only screen and (min-width: 1366px) {
    .car {
        width: 417px;
        max-width: none;
        top: 81px;
        left: 112px;
    }
}

@media only screen and (min-width: 1920px) {
    .car {
        width: 716px;
        top: -40px;
        left: 124px;
    }
}
</style>