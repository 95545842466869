<template>
    <div class="container menu">
        <div class="all-buttons">
            <div class="nav-button products" @click="goToLink('#aviation')">{{ $t('button_products') }}</div>
            <div class="nav-button contacts" @click="goToLink('#contacts')">{{
                $t('button_contacts') }}</div>
        </div>
        <div>
            <div v-for="lang, i in langs" :key="i" @click="changeLang(lang)" class="lang-button" :class="lang.class">
                {{ lang.name }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: {
        langs: Array,
    },
    methods: {
        goToLink(link) {
            window.location.href = link;
            this.$emit('close');
        },
        changeLang(lang) {
            this.$emit('changeLang', lang);
            this.$emit('close');
        }
    }
}
</script>

<style>
html {
    /* overflow: hidden; */
}
</style>

<style lang="scss" scoped>
.container {
    position: fixed;
    // margin-left: 19px;
    top: 60px;
    left: 0;
    height: calc(100vh - 60px);
    z-index: 9;
    background: #FFFFFFbb;
}

.all-buttons {
    display: block !important;
    margin-top: 20vh;
    font-family: "ABCNormal-Light";
}

.nav-button {
    display: block;
    font-size: 30px;
    transform: translateY(100px);
    opacity: 0;
    animation-name: slide-up;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    // line-height: 37px;
    &.products {
        margin-bottom: 30px;
        width: 175px;
    }

    &.contacts {
        // animation-delay: 50ms;
        transform: translateY(120px);
    }
}

.lang-button {
    position: absolute;
    bottom: calc(13vh);

    &.second {
        left: 80px;
    }
}

@media only screen and (min-width: 680px) {
    .container {
        top: 76px;
        height: calc(100vh - 111px);
        left: -5px;
    }

    .lang-button {
        bottom: calc(13vh + 15px);
        &.second {
            left: calc(10vw + 80px);
        }
    }
}

@media only screen and (min-width: 1366px) {
    .menu {
        display: none;
    }

}

@keyframes slide-up {
    0% {
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>