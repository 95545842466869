import authAPI from "@/API/endpoints/auth";
import Vue from "vue";

const state = {
  user: null,
};

const getters = {
  getUser(state) {
    state.user = Vue.$cookies.get('user');
    return state.user
  }
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    user
        ? Vue.$cookies.set("user", user, 60*60)
        : Vue.$cookies.remove("user");
  },
};

const actions = {
  async signInUser({ commit }, payload) {
    Vue.swal.fire({
      icon: "loading",
      title: "AUTHORIZING...",
      width: "300px",
      customClass: {
        title: "custom-swal-loading",
      },
      didOpen: async () => {
        Vue.swal.showLoading();
        try {
          const res = await authAPI.signInUser(payload);
          if (res.data.error) {
              const errorText = res.data.error.includes("auth")
                  ? res.data.error
                        .split("/")[1]
                        .replaceAll("-", " ")
                        .toUpperCase()
                  : res.data.error;
              Vue.swal.fire({
                  width: "300px",
                  icon: "error",
                  title: "Error",
                  text: errorText,
              });
          } else {
              Vue.swal.fire({
                  icon: "success",
                  title: "LOGIN SUCCESS",
                  showConfirmButton: false,
                  timer: 1000,
                  width: "300px",
                  customClass: {
                      title: "custom-swal-title",
                  },
              });
              commit("setUser", res.data.user);
          }
        } catch(error) {
            Vue.swal.fire({
                width: "300px",
                icon: "error",
                title: "Error",
                text: error,
            });
        }
      },
    });
  },
  async signOutUser({ commit }) {
    commit("setUser", null);
  },
  async checkUser({commit}) {
    try{
      const res = await authAPI.checkUser();
      if (res.status === 200) {
          const user = Vue.$cookies.get("user");
          commit("setUser", user);
      } else {
          Vue.$cookies.remove("user");
      }
    }
    catch(err) {
      Vue.$cookies.remove("user");
      console.log("Error is ", err);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
