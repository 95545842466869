import axios from "axios";
import cookies from "vue-cookies";

const user = cookies.get("user");

const BASE_URL = "https://api.aerosure.az/api/auth";

const APIClient = axios.create({
    baseURL: "https://api.aerosure.az/api/auth",
    headers: {
        Authorization: user ? `Bearer ${user.token}` : "",
    },
});

export default {
  signInUser(payload) {
    return axios.post(`${BASE_URL}/login`, {
      email: payload.email,
      password: payload.password,
    });
  },
  checkUser() {
    return APIClient.get('/session')
  }
};
