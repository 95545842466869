import Vue from "vue";
import Router from "vue-router";
import Admin from "./pages/dashboard/AdminPanel";
import Home from "./pages/main/Home";
import Admins from "./components/dashboard/admins/Admins";
import Translations from "./components/dashboard/translations/Translations";
import Categories from "./components/dashboard/categories/Categories";
import Subcategories from "./components/dashboard/subcategories/Subcategories";

Vue.use(Router);

const adminRoutes = [
    {
        path: "admins",
        name: "Admins",
        component: Admins,
    },
    {
        path: "translations",
        name: "Translations",
        component: Translations,
    },
    {
        path: "categories",
        name: "Categories",
        component: Categories,
    },
    {
        path: "subcategories",
        name: "Subcategories",
        component: Subcategories,
    },
];

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        children: adminRoutes,
    },
];

export { adminRoutes };

export default new Router({
    mode: "history",
    routes: routes,
});
