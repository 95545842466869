<template>
    <Product :animating="isActive == 4" :onlyContent="onlyContent" bgColor="#BEFFEF" color="#57DCBB" :categoryTag="categoryTag">
      <template v-slot:image>
        <img class="glasses" src="../../../assets/images/chemical/1920/chemical.png" alt="">
      </template>
      <template v-slot:arrow>
        <img src="../../../assets/images/arrow-turqoise.svg" alt="" class="arrow" />
      </template>
    </Product>
</template>

<script>
import Product from './Product';

export default {
  name: 'RawChemicals',
  components: {
    Product,
  },
  props: {
    onlyContent: Boolean,
    isActive: Number,
  },
  data() {
    return {
      categoryTag: "raw_chemicals"
    }
  }
}
</script>

<style >
.glasses {
  width: calc(100vw - 112px);
  max-width: 460px;
  margin-top: -25px;
  margin-left: 57px;
  padding-bottom: 17px;
}

@media only screen and (min-width: 1366px) {
  .glasses {
    width: 407px;
    margin-top: 159px;
    margin-left: 117px;
  }
}
@media only screen and (min-width: 1920px) {
  .glasses {
    width: 698px;
    margin-top: 85px;
    margin-left: 133px;
    max-width: none;
  }
}
</style>