<template>
    <div>
        <v-form v-model="valid" @submit.prevent="createAdmin(email, password, name)">
            <v-container class="pl-0">
                <v-row class="mt-2">
                    <v-col>
                        <v-text-field solo dense v-model="email" type="email" :rules="emailRules" label="Enter email"
                            required>
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field solo dense ref="password" v-model="password" :rules="passwordRules"
                            label="Enter password" type="password" required>
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field solo dense v-model="name" :rules="nameRules" label="Enter name" type="text" required>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3" class="text-start">
                        <v-btn color="success" type="submit" class="pr-5">Create</v-btn>
                        <v-btn class="ml-3" @click="$emit('close')">Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'CreateAdmin',
    data() {
        return {
            valid: false,
            email: '',
            name: '',
            password: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => !!v || `The email and password you entered don't match`,
                v => v.length > 5 || 'Password must be at least 6 characters long'
            ],
            nameRules: [
                v => !!v || "Name is required"
            ]
        }
    },
    methods: {
        createAdmin(email, password, name) {
            if (this.valid) {
                this.$store.dispatch("admins/createAdmin", { email, password, name });
                this.$emit('close');
            }
        }
    }
}
</script>

<style ></style>