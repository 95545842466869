<template>
    <v-row class="my-12">
        <v-col class="text-start mb-5 pt-0 pl-0" cols="12">
            <v-btn @click="showCreate = !showCreate" color="primary">Add new Translation</v-btn>
            <span class="mx-10 hint"><span class="text-h6">HINT:</span>  CLICK ON THE TRANSLATION TO EDIT</span>
        </v-col>
        <v-col class="table">
            <v-row class="pt-0 table-row">
                <v-col cols="2" class="table-cell">Tag</v-col>
                <v-col class="table-cell">EN</v-col>
                <v-col class="table-cell">AZ</v-col>
                <v-col class="table-cell">RU</v-col>
                <v-col class="table-cell" cols="2">Delete</v-col>
            </v-row>
            <v-row class="pt-0 table-row" v-for="translation, i in translations" :key=i>
                <v-col cols="2" class="table-cell">{{ translation.tag }}</v-col>
                <v-col @click="selecectText(translation.tag, 'en', translation.en)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.en }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'az', translation.az)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.az }}</p>
                </v-col>
                <v-col @click="selecectText(translation.tag, 'ru', translation.ru)" class="table-cell ">
                    <p class="editable line-clamp-2">{{ translation.ru }}</p>
                </v-col>
                <v-col class="table-cell" cols="2">
                    <v-btn @click="deleteTranslation(translation.tag)" color="error">DELETE</v-btn>
                </v-col>
            </v-row>
            <v-dialog v-model="showCreate" width="540">
                <template>
                    <CreateTranslation @close="showCreate = !showCreate" />
                </template>
            </v-dialog>
            <v-dialog v-model="showUpdate" width="540">
                <template>
                    <v-col class="white mt-0 pt-0 text-center">
                        <v-form @submit.prevent="updateTranslation">
                            <h2 class="my-3 pt-2 ">Edit Translation</h2>
                            <v-textarea ref="editInput" solo dense v-model="toBeUpdated.text" required
                                label="Enter text"></v-textarea>
                            <v-btn color="success" type="submit">Submit</v-btn>
                            <v-btn class="ml-3" @click="showUpdate = false">Cancel</v-btn>
                        </v-form>
                    </v-col>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import CreateTranslation from './CreateTranslation.vue';

export default {
    name: "Translations",
    data() {
        return {
            showCreate: false,
            showUpdate: false,
            toBeUpdated: { tag: '', lang: '', text: '' },
        };
    },
    mounted(){
        this.$store.dispatch("translations/fetchTranslations")
    },  
    computed: {
        translations() {
            return this.$store.getters["translations/getTranslations"];
        }
    },
    methods: {
        deleteTranslation(tag) {
            this.$store.dispatch("translations/deleteTranslation", tag)
        },
        selecectText(tag, lang, text) {
            this.showUpdate = true;
            setTimeout(() => {
                this.$refs.editInput.focus();
            }, 10)
            this.toBeUpdated = { tag, lang, text };
        },
        async updateTranslation() {
            await this.$store.dispatch("translations/updateTranslation", this.toBeUpdated);
            await this.$store.dispatch('categories/fetchCategories')
            this.showUpdate = false;
        },
        saveTranslations(){
            this.$store.dispatch("translations/saveTranslations");
        }
    },
    components: { CreateTranslation }
}
</script>

<style>
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* font-size: 14px; */
    word-break: keep-all !important;
    line-height: 20px;
    max-height: 50px;
    max-width: 167px;
    word-wrap: break-word;
}

.hint{
    /* font-size: 18px; */
}
</style>