<template>
    <v-row class="my-12">
        <v-col class="text-start mb-5 pt-0 pl-0" cols="12">
            <v-btn @click="showCreate = !showCreate" color="primary">Add new Admin</v-btn>
        </v-col>
        <v-col cols="12" class="mt-n16 pl-0 ml-0">
            <CreateAdmin v-if="showCreate" @close="showCreate = !showCreate" />
        </v-col>
        <v-col class="table">
            <v-row class="pt-0 table-row">
                <v-col cols="1" class="table-cell">ID</v-col>
                <v-col class="table-cell">Email</v-col>
                <v-col class="table-cell">Name</v-col>
                <v-col class="table-cell">Edit</v-col>
            </v-row>
            <v-row class="pt-0 table-row" v-for="admin, i in admins" :key=i>
                <v-col cols="1" class="table-cell">{{ i }}</v-col>
                <v-col class="table-cell">{{ admin.email }}</v-col>
                <v-col class="table-cell">{{ admin.name }}</v-col>
                <v-col class="table-cell">
                    <v-btn @click="toggleUpdate(admin)" color="success" class="mr-3">EDIT</v-btn>
                    <v-btn @click="deleteAdmin(admin)" color="error">DELETE</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-dialog v-model="showUpdate" width="360">
            <template>
                <v-col class="white mt-0 pt-0 text-center">
                    <v-form @submit.prevent="updateAdmin">
                        <h2 class="my-3 pt-2 ">Edit name</h2>
                        <v-text-field ref="nameInput" solo dense v-model="name" :rules="nameRules" label="Enter name"
                            type="text" required>
                        </v-text-field>
                        <v-btn color="success" type="submit">Submit</v-btn>
                        <v-btn class="ml-3" @click="showUpdate = false">Cancel</v-btn>
                    </v-form>
                </v-col>
            </template>
        </v-dialog>
    </v-row>
</template>

<script>
import CreateAdmin from './CreateAdmin';

export default {
    name: 'Admins',
    data() {
        return {
            showCreate: false,
            showUpdate: false,
            name: '',
            toBeUpdated: "",
            nameRules: [
                v => !!v || "Name is required"
            ]
        }
    },
    components: {
        CreateAdmin,
    },
    created() {
        if (!this.admins.length) {
            this.$store.dispatch("admins/fetchAdmins");
        }
    },
    computed: {
        admins() {
            return this.$store.getters['admins/getAdmins'];
        }
    },
    methods: {
        toggleUpdate(admin) {
            this.showUpdate = true;
            this.toBeUpdated = admin.email;
            this.name = admin.name;
            setTimeout(() => {
                this.$refs.nameInput.focus();
            }, 10)
        },
        updateAdmin() {
            this.$store.dispatch("admins/updateAdmin", { email: this.toBeUpdated, name: this.name });
            this.showUpdate = false;
        },
        deleteAdmin(admin) {
            this.$store.dispatch("admins/deleteAdmin", { email: admin.email });
        }
    }
}
</script>

<style lang="scss">
.table {
    border: 1px solid cyan;
    border-right: none;
    border-top: none;
    background: #00FFFF04;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.table-row {
    border-top: 1px solid cyan;
    margin-bottom: 0 !important;
}

.table-cell {
    border-right: 1px solid cyan;
    height: 60px !important;
    display: flex;
    align-items: center !important;
    justify-content: center;

    .editable {
        cursor: pointer;
        margin-bottom: 0;
    }
}

.row+.row {
    padding-top: 0 !important;
}
</style>