import Vue from "vue";
import Vuex from "vuex";
import adminsModule from "./modules/adminsModule";
import authModule from "./modules/authModule";
import translationsModule from "./modules/translationsModule";
import categoriesModule from "./modules/categoriesModule";
import subcategoriesModule from "./modules/subcategoriesModule";

Vue.use(Vuex);

const options = {
    state: {
        screenWidth: window.innerWidth,
    },
    getters: {
        isMobile(state) {
            return state.screenWidth < 1366;
        },
    },
    mutations: {
        setScreenWidth(state, width) {
            state.screenWidth = width;
        },
    },
    actions: {},
    modules: {
        admins: adminsModule,
        auth: authModule,
        translations: translationsModule,
        categories: categoriesModule,
        subcategories: subcategoriesModule,
    },
};

export default new Vuex.Store(options);
