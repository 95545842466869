<template>
    <div class="container product">
        <div class="row">
            <div v-if="isMobile" class="col-12 product-flex">
                <div class="box smaller" :style="'background: ' + bgColor">
                    <slot name="image" />
                </div>
                <div class="smaller">
                    <div class="areas" :style="'color: ' + color">
                        {{ $t('title_business') }}
                    </div>
                    <div class="cat-title">
                        {{ ($t(categoryTag)).split(' ').slice(0, splitIndex).join(' ') }}
                    </div>
                    <div class="cat-title">
                        {{ ($t(categoryTag)).split(' ').slice(splitIndex).join(' ') }}
                    </div>
                    <div>
                        <slot name="arrow" />
                    </div>
                    <div class="bigger">
                        <p v-for="subcat, i in filteredSubcategories" :key="i" class="subcategory">
                            {{ $t(subcat.tag) }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-else class="col-12 product-flex">
                <div class="box smaller" :style="'background: ' + (onlyContent ? 'transparent' : bgColor)">
                    <slot :style="'opacity: ' + (onlyContent ? '1' : '0')" v-if="onlyContent" name="image" />
                </div>
                <div class="smaller">
                    <div class="areas" :style="'color: ' + (onlyContent ? 'transparent' : color)">
                        {{ $t('title_business') }}
                    </div>
                    <div :style="'opacity: ' + (onlyContent ? '1' : '0')" class="cat-title">
                        {{ ($t(categoryTag)).split(' ').slice(0, splitIndex).join(' ') }}
                    </div>
                    <div :style="'opacity: ' + (onlyContent ? '1' : '0')" class="cat-title">
                        {{ ($t(categoryTag)).split(' ').slice(splitIndex).join(' ') }}
                    </div>
                    <div :style="'opacity: ' + (onlyContent ? '0' : '1')">
                        <slot name="arrow" />
                    </div>
                    <div :style="'opacity: ' + (onlyContent ? '1' : '0')">
                        <div v-if="filteredSubcategories.length > 7" class="d-flex bigger two-cols justify-space-between">
                            <div class="sliced justify-end">
                                <p :class="{ animating, ['subcat-' + i]: true }"
                                    v-for="subcat, i in filteredSubcategories.slice(0, filteredSubcategories.length / 2)"
                                    :key="i" class="subcategory first">
                                    {{ $t(subcat.tag) }}
                                </p>
                            </div>
                            <div class="sliced justify-end">
                                <p :class="{ animating, ['subcat-' + i]: true }"
                                    v-for="subcat, i in filteredSubcategories.slice(filteredSubcategories.length / 2)"
                                    :key="i" class="subcategory second">
                                    {{ $t(subcat.tag) }}
                                </p>
                            </div>
                        </div>
                        <div v-else class="bigger">
                            <p :class="{ animating, ['subcat-' + i]: true }" v-for="subcat, i in filteredSubcategories"
                                :key="i" class="subcategory">
                                {{ $t(subcat.tag) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Product',
    props: {
        bgColor: String,
        color: String,
        categoryTag: String,
        onlyContent: Boolean,
        isActive: Number,
        animating: Boolean,
        splitIndex: {
            type: Number,
            default: 1,
        }
    },
    computed: {
        subcategories() {
            return this.$store.getters["subcategories/getSubcategories"];
        },
        filteredSubcategories() {
            return this.subcategories.filter((sub) => sub.category === this.categoryTag).sort((a, b) => a.id - b.id) || []
        },
        isMobile() {
            return this.$store.getters.isMobile;
        }
    }
}
</script>

<style lang="scss">
@import "@/scss/mixins/text-mixins.scss";

.box {
    background: #BFD9FF;
    border-radius: 20px;
    margin-top: 50px;
    margin-right: 24.9vw;
    aspect-ratio: 0.823;
    max-height: 480px;
    position: relative;
}

.product {
    margin-top: 144px;
}

.areas {
    margin-top: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 30px;
    // width: 179px;
    width: fit-content;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    //Font
    font-family: "ABCNormal";
    font-size: 16px;
    color: #5795F8;
    line-height: 20px;
}

.cat-title {
    margin-top: 10px;
    margin-left: 12px;
    width: 225px;
    //Font
    font-family: "BlackerProDisplay";
    font-size: 36px;
    color: #0F0F10;
    line-height: 50px;
}

.arrow {
    margin-top: 34px;
    margin-left: 15px;
    margin-bottom: 15px;
    animation-delay: 0.5s;
}

.subcategory {
    margin-top: 20px;
    margin-left: 114px;
    //Font
    font-family: "ABCNormal";
    font-size: 14px;
    color: #0F0F10;
    line-height: 17px;
    margin-bottom: 0 !important;
}

// .v-application .two-cols {
//     display: none;
// }

@media only screen and (min-width: 680px) and (max-width: 1366px) {
    .subcategory {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1366px) {
    .product {
        // margin-top: 214px !important;
        margin-top: 0;
        min-height: 100vh;
    }

    .product-flex {
        display: flex;
        // margin-top: 214px !important;
        margin-top: calc((100vh - 560px)/2) !important;
    }

    .box {
        margin-top: 0;
        margin-right: 185px;
        width: 414px;
        height: 560px;
        max-height: none;
        animation-delay: 0.5s;
    }

    .areas {
        margin-top: 0;
        // width: 213px;
        padding: 0 30px;
        height: 44px;
        //Font
        font-size: 20px;
        line-height: 24px;
    }

    .cat-title {
        margin-top: 10px;
        width: fit-content;
    }

    .subcategory {
        margin-left: 85px;
        margin-top: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        //Font
        font-size: 18px;
        line-height: 22px;

        &.first {
            width: 205px;
        }

        &.second {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .arrow {
        margin-top: 14px;
    }

    @for $i from 0 through 10 {
        .subcat-#{$i} {
            @include lineAnimation(0.6, $i);
        }
    }
}

@media only screen and (min-width: 1700px) {
    .v-application .two-cols {
        display: flex !important;
        margin-left: 0px;
    }

    .one-col {
        display: none;
    }

    .box {
        margin-right: 365px;
    }

    .smaller {
        transform: scale(1.1);
        transform-origin: left;
    }
}

@media only screen and (min-width: 1920px) {
    .product {
        // margin-top: 264px;
    }

    .one-col {
        display: none;
    }

    .product-flex {
        // margin-top: 264px;
        margin-top: calc((100vh - 760px)/2) !important;
    }

    .box {
        margin-right: 388px;
        width: 620px;
        height: 760px;
    }

    .areas {
        // width: 276px;
        height: 60px;
        //Font
        font-size: 25px;
        line-height: 31px;
    }

    .cat-title {
        margin-top: 19px;
        //Font
        font-size: 70px;
        line-height: 84px;
    }

    .subcategory {
        //Font
        font-size: 20px;
        line-height: 24px;
    }

    .sliced {
        .subcategory {
            margin-left: 0;
            margin-top: 5px;
            height: 44px;
            display: flex;
            align-items: center;
        }

        width: 232px;
    }

    .v-application .two-cols {
        display: flex !important;
        margin-left: 114px;
    }

    .smaller {
        transform: scale(0.9);
        transform-origin: left;
    }

    .bigger {
        transform: scale(1.11);
        transform-origin: left;
    }
}
</style>