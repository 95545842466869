<template>
    <div class="white text-center">
        <v-form ref="form" v-model="valid" @submit.prevent="createSubcategory(translation)">
            <v-container>
                <h2>Create Subcategory</h2>
                <v-col class="pb-0">TAG:</v-col>
                <v-row>
                    <v-col>
                        <v-text-field required :rules="tagRules" class="mt-3" v-model="translation.tag" solo
                            placeholder="Enter tag">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-col class="mb-1">TEXT:</v-col>
                <v-row>
                    <v-col>
                        <v-btn :color="selectedLanguage === 'en' ? 'primary' : ''" @click="setLanguage('en')">EN</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn :color="selectedLanguage === 'az' ? 'primary' : ''" @click="setLanguage('az')">AZ</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn :color="selectedLanguage === 'ru' ? 'primary' : ''" @click="setLanguage('ru')">RU</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="mt-2" v-show="selectedLanguage === 'en'">
                        <v-textarea required :rules="textRules" v-model="translation.enText" solo
                            placeholder="Enter text in EN">
                        </v-textarea>
                    </v-col>
                    <v-col class="mt-2" v-show="selectedLanguage === 'az'">
                        <v-textarea required :rules="textRules" v-model="translation.azText" solo
                            placeholder="Enter text in AZ">
                        </v-textarea>
                    </v-col>
                    <v-col class="mt-2" v-show="selectedLanguage === 'ru'">
                        <v-textarea required :rules="textRules" v-model="translation.ruText" solo
                            placeholder="Enter text in RU">
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                    <v-col cols="6">
                        <v-btn class="mr-5" color="success" type="submit">
                            Submit
                        </v-btn>
                        <v-btn @click="$emit('close')">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
export default {
    name: 'CreateSubcategory',
    data() {
        return {
            valid: false,
            tagRules: [
                v => !!v || "Tag is required"
            ],
            textRules: [
                v => !!v || "Text is required"
            ],
            selectedLanguage: "en",
            translation: {
                enText: "",
                azText: "",
                ruText: "",
                tag: "",
            },
        }
    },
    props: {
        category: Object,
    },
    mounted() {
        this.$refs.form.reset();
    },
    methods: {
        setLanguage(lang) {
            this.selectedLanguage = lang
        },
        async createSubcategory(payload) {
            if (await this.$refs.form.validate()) {
                const translation = { ...payload }
                this.$store.dispatch("subcategories/createSubcategory", { tag: translation.tag, category: this.category.tag })
                this.$store.dispatch("translations/createTranslation", translation);
                this.$refs.form.reset();
                this.$emit("close");
            }
        }
    }
}
</script>

<style ></style>