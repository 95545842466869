<template>
    <div class="white">
        <v-form ref="form" v-model="valid" @submit.prevent="signInUser(email, password)">
            <v-container>
                <h2 class="text-center blue--text text--darken-2">ADMIN PANEL</h2>
                <v-row class="mt-2">
                    <v-col>
                        <v-text-field v-model="email" type="email" :rules="emailRules" label="Enter email" required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-1">
                    <v-col>
                        <v-text-field ref="password" v-model="password" :rules="passwordRules" label="Enter password"
                            type="password" required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn block type="submit">Sign In</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>

export default {
    name: 'Auth',
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
                v => !!v || `The email and password you entered don't match`
            ],
        }
    },
    methods: {
        signInUser(email, password) {
            this.$store.dispatch("auth/signInUser", { email, password })
        },
    }
}
</script>

<style lang="scss">
@import "~firebaseui/dist/firebaseui.css";

.row+.row {
    margin-top: 0 !important;
}

.custom-swal-title {
    color: green !important;
    font-size: 24px !important;
}

.custom-swal-loading {
    font-size: 16px !important;
}

.swal2-error {
    border-width: 3px !important;
    width: 3em !important;
    height: 3em !important;

    &~.swal2-title {
        padding-top: 12px !important;
    }

    .swal2-x-mark-line-left {
        top: 2.3125em !important;
        left: 1.0625em !important;
        font-size: 0.6rem !important;
    }

    .swal2-x-mark-line-right {
        top: 2.3125em !important;
        font-size: 0.6rem !important;
        right: 1em !important
    }
}
</style>